import React, { useState } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import './cancel-success.css';
import Img from 'gatsby-image';
import Button from '../components/buttons/button';
import { ButtonColor, ButtonStyle } from '../models/button-style';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { Route } from '../models/site-links';

const ReferralSuccess = () => {
  const data = useStaticQuery(graphql`
    query {
      cancelSuccess: file(relativePath: { eq: "cancel/cancel-success.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <div className="flex justify-center">
        <div className="mb-20 mt-10">
          <p className="text-cancelheader font-semibold mt-24 text-center">
            Thank You for Sending a Referral!
          </p>
          <p className="text-sh2 font-normal text-neutral-700 text-center">
            You've{' '}
            <strong>
              <i>successfully referred</i>
            </strong>{' '}
            a friend to Patronscan.
          </p>
          <Img
            className="h-44 w-44 justify-center text-center mt-20 ml-30vw"
            fluid={data.cancelSuccess.childImageSharp.fluid}
          />
          <div className="mt-32 ml-50vw">
            <AniLink
              fade
              className="block hover:text-grey-dark transition-color lg:text-base industry-picks"
              to="https://www.patronscan.com"
            >
              <Button
                buttonColor={ButtonColor.FormSubmission}
                buttonStyle={ButtonStyle.CancelOffer}
                text={'Done'}
                buttonId={'CancelSubscriptionButton'}
              />
            </AniLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralSuccess;
